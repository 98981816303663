import Navbar from "./Navbar";
import AboutBackground from "../Assets/About/about_bkg.jpeg"
import Developer from "../Assets/About/developer.jpeg"
import Chip from "../Assets/About/chip.jpeg"

const About = () => {
    return (
        <div className="home-container">

            <Navbar />

            <div className="home-banner-container">
                <div className="home-text-section">
                    <h1 className="primary-heading">
                        Sobre nós
                    </h1>
                    <p className="primary-text">
                        Tornar o alimento universal utilizando-se de tecnologias de
                        integração de dados, analises e inteligência artificial.
                        <br />
                        Promover altíssimo desempenho e padrões excepcionais,
                        aumentando assim, a produtividade em áreas disponíveis e evitando a necessidade de expansão 
                        otimizando insumos e priorizando a preservação.
                    </p>
                </div>

                <div className="about-image-section">
                    <img src={AboutBackground} alt="" />
                </div>
            </div>

            <h1 className="primary-subheading">Mais de 20 anos de experiência no desenvolvimento de tecnologias</h1>

            <div className="about-image-container">
                <div className="about-image-item">
                    <img className="about-image" src={Developer} alt="" />
                </div>
                <div className="about-image-item">
                    <img className="about-image" src={Chip} alt="" />
                </div>
            </div>

            <div className="secondary-text">
                Vasta experiência no desenvolvimento de softwares de gestão do ramo agrícola,
                com foco na produtividade e facilidade na aquisição e tratamento de dados do campo,
                gerando resultados consistentes, melhorando a competitividade de nossos clientes,
                com a gestão de recursos e aumento de produtividade.
                <br />
                Com o desenvolvimento de hardwares específicos para o monitoramento de parametros,
                e a utilização de tecnologias avançadas como processos produtivos da indústria 5.0 e Inteligências artificiais,
                os resultados exerados são exponencializados.
            </div>
        </div>
    );
};

export default About;