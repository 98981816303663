import Navbar from "./Navbar";
import Coworking from "../Assets/Contact/coworking.jpg"

const Contact = () => {
  return (
    <div className="home-container">
      <Navbar />

      <div className="contact-page-wrapper">
        <div className="whatsapp-text">
          <a href="https://api.whatsapp.com/send?phone=+5544991848774&text=Ol%C3%A1!%20Quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20A5" rel="noreferrer" target="_blank">
            <img src="https://cdn.unimake.app/WhatsAppButtonGreenSmall.svg" alt="" />
          </a>
        </div>
        <br />

        <div className="contact-page-map">
          <img src={Coworking} alt="" />
          <iframe
            title="Biopark"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4313.493272109844!2d-53.712869567311955!3d-24.61675528159986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f3bd769c061531%3A0x63d0e45c48b81246!2sBiopark!5e0!3m2!1spt-BR!2sbr!4v1701974400293!5m2!1spt-BR!2sbr">
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
