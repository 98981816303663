import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import About from './Components/About';
import Services from "./Components/Services";
import Footer from "./Components/Footer";
import HowItWorks from "./Components/HowItWorks";
import Contact from "./Components/Contact";

// @ts-ignore

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <div className="App">
        <React.StrictMode>
            <Router>
                <Routes>
                    <Route path="/" element={<App />}></Route>
                    <Route path="/home" element={<Home />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/howItWorks" element={<HowItWorks />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            </Router>

            <Footer />

        </React.StrictMode>
    </div>
);
