import FlatTerrain from "../Assets/flat_terrain.jpg";
import QuickAnalytics from "../Assets/quick_analytics.png";
import SoilAnalysis from "../Assets/local_soil_analysis.jpg";
import DataProcessing from "../Assets/data_processing.jpg";
import { FiArrowRight } from "react-icons/fi";
import Navbar from "./Navbar";

const Home = () => {

    const handleClick = () => {
        window.open('https://api.whatsapp.com/send?phone=+5544991848774&text=Olá! Quero agendar uma apresentação do produto Agro5.0', '_blank');
    };

    return (
        <div className="home-container">
            <Navbar />

            <div className="home-banner-container">
                <div className="home-text-section">
                    <h1 className="primary-heading">
                        Tecnologia aplicada a produtividade.
                    </h1>
                    <p className="primary-text">
                        Obtenha os dados dos parâmetros e nutrientes do solo de forma ágil e constante,
                        com sensores de alta tecnologia e análises em tempo real para auxiliá-lo
                        nas tomadas de decisões!
                        <br />
                        Seja rápido e assertivo.
                    </p>
                    <button className="secondary-button" onClick={handleClick}>
                        Agendar com especialista<FiArrowRight />{" "}
                    </button>
                </div>
                <div className="home-image-section">
                    <img src={FlatTerrain} alt="" />
                </div>
            </div>

            <h1 className="primary-subheading">Sistema integrado de análises de solo.</h1>
            <br />

            <div className="home-image-analytic-container">
                <div className="home-image-analytic-item">
                    <img className="home-image-analytic" src={SoilAnalysis} alt="" />
                    <p className="home-image-analytic-text">Análise local</p>
                </div>
                <div className="home-image-analytic-item">
                    <img className="home-image-analytic" src={DataProcessing} alt="" />
                    <p className="home-image-analytic-text">Tratamento de dados</p>
                </div>
                <div className="home-image-analytic-item">
                    <img className="home-image-analytic" src={QuickAnalytics} alt="" />
                    <p className="home-image-analytic-text">Análises rápidas</p>
                </div>
            </div>

            <br />

            <div className="secondary-text">
                Gerencie a qualidade do solo, desde a camada superficial até abaixo das raízes
                com controle preciso e recomendações estratégicas personalizadas para suas culturas.
                <br />É possível diagnosticar áreas problemáticas e comparar o solo entre as zonas.
                <br />
                Combine a qualidade do solo com a necessidade de fertilizantes,
                poupando dinheiro e aumentando os rendimentos, enquanto melhora o tempo e a saúde do solo.
            </div>
        </div>
    );
};

export default Home;