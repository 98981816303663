import Navbar from "./Navbar";
import Fosforo from '../Assets/Services/fosforo.png'
import Nitrato from '../Assets/Services/umidade.png'
import PhDoSolo from '../Assets/Services/ph_do_solo.png'
import Salinidade from '../Assets/Services/salinidade.png'
import Postassio from '../Assets/Services/potassio.png'
import Umidade from '../Assets/Services/umidade.png'
import SoilTrado from '../Assets/Services/soil_trado.jpeg'

const Services = () => {
    return (
        <div className="home-container">

            <Navbar />

            <div className="home-banner-container">
                <div className="home-text-section">
                    <h1 className="primary-heading">
                        Logística reduzida
                    </h1>
                    <p className="primary-text">
                        Dados do solo em tempo real.
                    </p>
                </div>

                <div className="services-image-section">
                    <img src={SoilTrado} alt="" />
                </div>
            </div>

            <h1 className="primary-subheading">Tudo que você precisa para manter a saúde do solo</h1>

            <div className="secondary-text">
                Chega de coleta e envio de solo para análises,
                resultados de testes de solo demorados ou inspeção visual.
                <br />
                Os sensores de monitoramento verificam e relatam constantemente a qualidade do solo
                24 horas por dia e 365 dias por ano.
                <br />
                Os agricultores agora podem responder às mudanças nos campos com mais rapidez,​ antes que se tornem problemas.
            </div>

            <div className="services-image-container">
                <div className="services-image-item">
                    <img className="services-image" src={Umidade} alt="" />
                    <p className="services-image-subheader">Umidade</p>
                    <p className="services-image-text">O conteúdo de água disponível (AWC) do solo, ou a quantidade de água disponível para a planta absorver.</p>
                </div>
                <div className="services-image-item">
                    <img className="services-image" src={Salinidade} alt="" />
                    <p className="services-image-subheader">Salinidade</p>
                    <p className="services-image-text">O teor total de sal no solo, relatado como condutividade elétrica (CE).</p>
                </div>
                <div className="services-image-item">
                    <img className="services-image" src={Nitrato} alt="" />
                    <p className="services-image-subheader">Nitrato</p>
                    <p className="services-image-text">Uma das formas inorgânicas do macronutriente, o nitrogênio, um elemento químico que ocorre naturalmente no solo e que as plantas utilizam para o crescimento.</p>
                </div>
            </div>

            <div className="services-image-container">
                <div className="services-image-item">
                    <img className="services-image" src={Postassio} alt="" />
                    <p className="services-image-subheader">Potássio</p>
                    <p className="services-image-text">Um elemento químico que ocorre naturalmente no solo e proporciona às plantas melhor crescimento radicular e tolerância à seca.</p>
                </div>
                <div className="services-image-item">
                    <img className="services-image" src={Fosforo} alt="" />
                    <p className="services-image-subheader">Fósforo</p>
                    <p className="services-image-text">Elemento químico que ocorre naturalmente no solo e é necessário para diversas funções das plantas.</p>
                </div>
                <div className="services-image-item">
                    <img className="services-image" src={PhDoSolo} alt="" />
                    <p className="services-image-subheader">PH do Solo</p>
                    <p className="services-image-text">A medida da acidez ou alcalinidade de um solo, também conhecida como reação do solo.</p>
                </div>
            </div>
        </div>
    );
};

export default Services;
