import Navbar from "./Navbar";
import HowItWorksBackground from "../Assets/HowItWorks/HowItWorks_bkg.jpeg"
import Analytics from "../Assets/HowItWorks/analytics.png"
import Barn from "../Assets/HowItWorks/barn.png"
import NutrientAnalysis from "../Assets/HowItWorks/nutrient_analysis.png"
import Probe from "../Assets/HowItWorks/probe.png"
import UI from "../Assets/HowItWorks/ui.png"

const HowItWorks = () => {
    return (
        <div className="home-container">

            <Navbar />

            <div className="home-banner-container">
                <div className="home-text-section">
                    <h1 className="primary-heading">
                        Como Funciona
                    </h1>
                    <p className="primary-text">
                        Nosso processo contínuo fornece aos agricultores dados em tempo real que eles podem usar para tomar decisões melhores.<br /><br />
                        Gerencie o solo em tempo real, reduza a emissão de carbono e aumente o lucro agrícola.<br /><br />
                        Utilizando nossos sensores, os agricultores podem reduzir os custos com fertilizantes e aumentar o rendimento das colheitas.<br /><br />
                        O A5 permite que os agricultores tomem decisões melhores em relação à sua lucratividade e emissões de carbono.
                    </p>
                </div>

                <div className="HowItWorks-image-section">
                    <img src={HowItWorksBackground} alt="" />
                </div>
            </div>

            <br />

            <h1 className="primary-subheading">Como seus dados chegam da sua fazenda até você</h1>

            <div className="HowItWorks-image-container">
                <div className="HowItWorks-image-item">
                    <img className="HowItWorks-image" src={Probe} alt="" />
                    <p className="HowItWorks-image-text">Primeiro, nossas sondas coletam dados de seus solos e os enviam os dados pelos coletores.</p>
                </div>
                <div className="HowItWorks-image-item">
                    <img className="HowItWorks-image" src={Barn} alt="" />
                    <p className="HowItWorks-image-text">Em seguida, nossos gateways agregam todos os dados de sondagem e os enviam para a nuvem em uma transmissão ao vivo e segura.</p>
                </div>
                <div className="HowItWorks-image-item">
                    <img className="HowItWorks-image" src={Analytics} alt="" />
                    <p className="HowItWorks-image-text">Em seguida, em nossa nuvem, executamos análises de seus dados com base nas condições do solo compiladas por governos, universidades, técnicos, especialistas e nos critérios exclusivos de sua fazenda.</p>
                </div>
                <div className="HowItWorks-image-item">
                    <img className="HowItWorks-image" src={UI} alt="" />
                    <p className="HowItWorks-image-text">Por fim, informamos você com informações preditivas e em tempo real usando gráficos legíveis e relatórios com recomendações personalizadas.</p>
                </div>
            </div>

            <br />


            <div className="home-banner-container">
                <div className="home-text-section">
                    <h1 className="primary-subheading">Análises de nutrientes</h1>
                    <div className="secondary-text">
                        Envio de relatórios de análise de área com nutrientes e com vários parametros estipulados pelos técnicos responsáveis,
                        baseando a tomada de decisão em dados recentes e com comparativos de longo prazo, com custo reduzido na aquisição,
                        logística e analises laboratoriais.
                        <br /><br />
                        A utilização desses dados para a tomada de decisão sobre: escolha de cultura, retorno esperado, custo de correção de solo,
                        e a otimização da aplicação dos corretivos para obter os resultados esperados.
                    </div>
                </div>

                <div className="HowItWorks-image-section">
                    <img src={NutrientAnalysis} alt="" />
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;