/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav>
            <div className="nav-logo-container">
                <img src="/logo192.png" alt="logo" />
            </div>

            <span className="navbar-header-text">A5 - Inteligência em Agro</span>

            <div className="navbar-links-container">
                <div className="navbar-links-container-icon"><Link to="/">Início</Link></div>
                <div className="navbar-links-container-icon"><Link to="/services">Serviços</Link></div>
                <div className="navbar-links-container-icon"><Link to="/about">Sobre</Link></div>
                <div className="navbar-links-container-icon"><Link to="/howItWorks">Como Funciona</Link></div>
                <div className="navbar-links-container-icon"><Link to="/contact">Contato</Link></div>
                <div className="navbar-links-container-icon"><a href="https://api.whatsapp.com/send?phone=+5544991848774&text=Ol%C3%A1!%20Quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20A5"
                    target="_blank"
                    rel='noreferrer'>
                    <button className="primary-button">+ Informações</button>
                </a></div>
            </div>
        </nav>
    );
};

export default Navbar;
