import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { Agriculture } from "@mui/icons-material";

const Footer = () => {
    return (
        <div className="footer-wrapper">
            <div className="contact-page-wrapper">
                <h1 className="primary-heading">Está com dúvidas? 🤔</h1>
                <h1 className="primary-heading">Deixe-nos ajudar você! 😊</h1>
                <div className="contact-form-container">
                    <input type="text" placeholder="seu e-mail" />
                    <button className="secondary-button">Enviar</button>
                </div>
            </div>

            <hr />

            <div className="footer-section-one">
                <div className="footer-logo-container">
                    <img src="logo192.png" alt="" />
                </div>
                <div className="footer-icons">
                    <BsTwitter />
                    <SiLinkedin />
                    <BsYoutube />
                    <FaFacebookF />
                </div>
            </div>
            <div className="footer-section-two">
                <div className="footer-section-columns">
                    <span>Ajuda</span>
                    <span>Compartilhar</span>
                    <span>Trabalhar conosco</span>
                </div>
                <div className="footer-section-columns">
                    <span><a href="https://api.whatsapp.com/send?phone=+5544991848774&text=Ol%C3%A1!%20Quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20A5">☎️ +55(44) 9 9184-8774</a></span>
                    <span>📧 <a href="mailto:duvidas@a5.agr.br?subject=Ol%C3%A1!%20Quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20A5">duvidas@a5.agr.br</a></span>
                    <span>📧 <a href="mailto:comercial@a5.agr.br?subject=Ol%C3%A1!%20Quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20A5">comercial@a5.agr.br</a></span>
                </div>
                <div className="footer-section-columns">
                    <span>Termos e Condições</span>
                    <span>Política de Privacidade</span>
                </div>
            </div>

            <span className="footer-section-copyright">
                <br />
                © Copyright {new Date().getFullYear()} / A5 - Inteigência em Agro <Agriculture /> </span>
        </div>
    );
};

export default Footer;